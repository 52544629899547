















































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import { GetZhuantiDetail } from "@/request/yian";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private data: any = {};
  private id: any = "";
  private ifShowTupu: any = false;
  private keys: any = [
    {
      name: "编号",
      label: "编码",
    },
    {
      name: "实体名称",
      label: "实体名称",
    },
    {
      name: "症状体征",
      label: "症状体征",
    },
    {
      name: "病因",
      label: "病因",
    },
    {
      name: "病机",
      label: "病机",
    },
    {
      name: "中医疾病",
      label: "中医疾病",
    },
    {
      name: "中医证型",
      label: "中医证型",
    },
    {
      name: "西医疾病",
      label: "西医疾病",
    },
    {
      name: "中医治法",
      label: "中医治法",
    },
    {
      name: "中医治则",
      label: "中医治则",
    },
    {
      name: "中医方剂",
      label: "中医方剂",
    },
    {
      name: "中药饮片",
      label: "中药饮片",
    },
  ];
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private get jibing() {
    return this.$store.state.jibing;
  }
   private get type() {
    return this.$store.state.zhuanti;
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        entity_id: this.id,
      },
    };
    GetZhuantiDetail(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
  }
}
